import React, { Component } from 'react';
import './ServicesPage.css'; // We'll style it later

export class ServicesPage extends Component {
    render() {
        return (
            <div className="services-container">
                <h1>Our Plans</h1>
                <p>Select the plan that best fits your business needs. Each plan comes with unique features to help you grow your business.</p>

                <div className="services-cards">
                    {/* Basic Plan Card */}
                    <div className="servicescard">
                        <h2>Basic Plan</h2>
                        <p>This plan is perfect for small businesses just getting started.</p>
                        <ul>
                            <li>Basic Inventory Management</li>
                            <li>Three Month Free</li>
                            <li>Track Stock Levels</li>
                            <li>Real-Time Updates for Low Stock</li>
                            <li>Multiple User Ability</li>
                            <li>Efficent Search Bar</li>
                            <li>Date Filter</li>

                        </ul>
                        <button className="cta-btn" onClick={() => window.location.href = '/createaccount'}>
                            Sign Up for Basic Plan
                        </button>
                    </div>

                    {/* Premium Plan Card */}
                    <div className="servicescard">
                        <h2>Premium Plan</h2>
                        <p>This plan is designed for growing businesses needing advanced features.</p>
                        <ul>
                            <li>Advanced Inventory Reports</li>
                            <li>Three Month Free</li>
                            <li>Food Waste Mangments</li>
                            <li>Track Stock Levels</li>
                            <li>Real-Time Alerts for Low Stock</li>
                            <li>Multiple User Ability</li>
                            <li>AI Gerate Reports</li>
                        </ul>
                        <button className="cta-btn" onClick={() => window.location.href = '/createaccount'}>
                            Sign Up for Premium Plan
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
