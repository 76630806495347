import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
import './Login.css';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: '',
            isAuthenticated: false, // Manage redirection
            token: '',
            showModal: false,
            newPassword: '',
            confirmPassword: ''
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        /*const saltRounds = 10;*/

        //try {
        //    // Hash the password
        //    const hashpassword = await new Promise((resolve, reject) => {
        //        bcrypt.hash(password, saltRounds, (err, hash) => {
        //            if (err) {
        //                reject(err);
        //            } else {
        //                resolve(hash);
        //            }
        //        });
        //    });
        try {
            const response = await fetch('/account/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            });

            // Log response status and text for debugging
            /* console.log('Response Status:', response.status);*/
            const responseText = await response.text();
            /*console.log('Response Text:', responseText);*/

            if (!response.ok) {
                this.setState({ error: responseText || 'Invalid credentials' });
                return;
            }

            // Attempt to parse JSON response
            try {
                const result = JSON.parse(responseText);
                /*console.log('Login Result:', result); // Log to check*/
                const { token } = result;

                // Save authentication status and token
                if (token) {
                    localStorage.setItem('auth', true);
                    localStorage.setItem('token', token);
                    this.setState({ isAuthenticated: true, token }); // Update state to trigger redirect
                } else {
                    this.setState({ error: 'No token received' });
                }
            } catch (jsonError) {
                console.error('Error parsing JSON:', jsonError);
                this.setState({ error: 'Failed to parse server response' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };

    handleForgotPassword = async (e) => {
        e.preventDefault();
        const { email, newPassword } = this.state;

        try {
            const response = await fetch('/account/forgotpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, newPassword })
            });

            const responseText = await response.text();
            if (!response.ok) {
                this.setState({ error: responseText || 'Invalid credentials' });
                return;
            }

            // Attempt to parse JSON response
            try {
                const result = JSON.parse(responseText);
                /*console.log('Login Result:', result); // Log to check*/
                const { token } = result;

                // Save authentication status and token
                if (token) {
                    localStorage.setItem('auth', true);
                    localStorage.setItem('token', token);
                    this.setState({ isAuthenticated: true, token }); // Update state to trigger redirect
                } else {
                    this.setState({ error: 'No token received' });
                }
            
            } catch (jsonError) {
                console.error('Error parsing JSON:', jsonError);
                this.setState({ error: 'Failed to parse server response' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };

    openModal = () => {
        this.setState({ showModal: true }); // Show modal when forgot password link is clicked
    };

    closeModal = () => {
        this.setState({ showModal: false }); // Close modal
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const { email, password, error, isAuthenticated, showModal, newPassword, confirmPassword } = this.state;

        if (isAuthenticated) {
            return <Navigate to="/inventorysystem" />; // Redirect to InventorySystem component
        }

        return (
            <div className="login-container">
                <h2 className="login-title">Login</h2>
                {error && <p className="login-error">{error}</p>}
                <form className="login-form" onSubmit={this.handleSubmit}>
                    <div className="Login-form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                            className="form-control"
                        />
                    </div>
                    <div className="Login-form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            required
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                    <p className="forgot-password-link" onClick={this.openModal}>Forgot Password?</p>

                </form>
                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Reset Your Password</h2>
                            <form onSubmit={this.handleForgotPassword}>
                                <div className="modal-form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="modal-form-group">
                                    <label htmlFor="newPassword">New Password:</label>
                                    <input
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        value={newPassword}
                                        onChange={this.handleChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className="modal-form-group">
                                    <label htmlFor="confirmPassword">Confirm New Password:</label>
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={this.handleChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <button type="submit" className="reset-password-button">Reset Password</button>
                            </form>
                            <button onClick={this.closeModal} className="close-modal-button">Close</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}