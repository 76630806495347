import React, { Component } from 'react';
import './PrivacyPolicy.css'; // Create a separate CSS file for styling

export class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="privacy-policy-container">
                <h1>Privacy Policy</h1>
                <p>Last Updated: January 1, 2024</p>

                <section>
                    <h2>Introduction</h2>
                    <p>
                        Welcome to SBISS. This Privacy Policy explains how we collect, use, and protect your data
                        when you use our services. By using our services, you agree to the collection and use of information in accordance
                        with this policy. While creating your account your agree all term of service.
                    </p>
                </section>

                <section>
                    <h2>Information We Collect</h2>
                    <ul>
                        <li>Personal Information: Name, email address, etc.</li>
                        <li>Usage Data: How you interact with our platform.</li>
                        <li>Cookies: For improving user experience and analytics.</li>
                    </ul>
                </section>

                <section>
                    <h2>How We Use Your Information</h2>
                    <ul>
                        <li>To provide and improve our services.</li>
                        <li>To communicate with you about your account or updates to our services.</li>
                        <li>For analytical purposes to understand how users interact with our platform.</li>
                    </ul>
                </section>

                <section>
                    <h2>Data Protection</h2>
                    <p>
                        We implement security measures to protect your data. However, no method of transmission over the Internet is 100% secure,
                        and we cannot guarantee its absolute security.
                    </p>
                </section>

                <section>
                    <h2>Your Rights</h2>
                    <ul>
                        <li>You can access, update, or delete your personal data at any time.</li>
                        <li>You have the right to opt-out of certain data collection methods like cookies.</li>
                    </ul>
                </section>

                <section>
                    <h2>Changes to This Privacy Policy</h2>
                    <p>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy
                        on this page. You are advised to review this Privacy Policy periodically for any updates.
                    </p>
                </section>
            </div>
        );
    }
}