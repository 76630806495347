import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Login } from "./components/Login";
import { CreateAccount } from "./components/CreateAccount";
import { InventorySystem } from "./components/InventorySystem";
import { FoodWastePage } from "./components/FoodWaste";
import { SubscriptionPage } from "./components/Subscription";
import { AnalysisPage } from "./components/AnalysisPage";
import { AboutPage } from "./components/AboutPage";
import { ServicesPage } from "./components/ServicesPage";
import { PrivacyPolicy } from "./components/PrivacyPolicyPage";


const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/login',
        element: <Login />
    }
    ,
    {
        path: '/createaccount',
        element: <CreateAccount />
    }
    ,
    {
        path: '/inventorysystem',
        element: <InventorySystem />
    },
    {
        path: '/foodwaste',
        element: <FoodWastePage />
    },
    {
        path: '/subscription',
        element: <SubscriptionPage />
    },
    {
        path: '/analysis',
        element: <AnalysisPage />
    },
    {
        path: '/about',
        element: <AboutPage />
    }
    ,
    {
        path: '/services',
        element: <ServicesPage />
    },
    {
        path: '/privacypolicy',
        element: <PrivacyPolicy />
    }

];

export default AppRoutes;